import { format, isValid } from "date-fns";

export const getIsRequiredText = (t, fieldLabelText) => {
  return t("globals.form.fieldIsRequired", { fieldName: fieldLabelText });
};

// Converts the given value into a Date object if valid; otherwise, returns null.
export const getIsValidDate = (dateValue) => {
  return dateValue ? new Date(dateValue) : null;
};

// Translates the Component form data into the format that API expects.
export const translateComponentFormData = (
  formData,
  piOrComponentOwnerValue
) => {
  const translatedComponentFormData = {
    ...formData,
    expenditures:
      Array.isArray(formData.expenditures) &&
      formData.expenditures.map((expenditure) => expenditure.value),
    componentIncrementals: formData.componentIncrementals?.map((component) => ({
      ...component,
      componentDate: isValid(component.componentDate)
        ? format(component.componentDate, "MM/dd/yyyy")
        : null,
    })),
    piOrComponentOwner: piOrComponentOwnerValue?.displayText,
    startDate: isValid(formData.startDate)
      ? format(formData.startDate, "MM/dd/yyyy")
      : null,
    expirationDate: isValid(formData.expirationDate)
      ? format(formData.expirationDate, "MM/dd/yyyy")
      : null,
    closedDate: isValid(formData.closedDate)
      ? format(formData.closedDate, "MM/dd/yyyy")
      : null,
  };
  return translatedComponentFormData;
};
