import { useEffect, useState } from "react";
import axios from "axios";
import * as Yup from "yup";
import { Formik } from "formik";
import { format, addDays, subDays } from "date-fns";
import { Trans, useTranslation } from "react-i18next";
import { NumericFormat } from "react-number-format";
import {
  Link as RouterLink,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import {
  Grid as MuiGrid,
  Button as MuiButton,
  Tooltip as MuiTooltip,
  Checkbox as MuiCheckbox,
  TextField as MuiTextField,
  IconButton as MuiIconButton,
  Typography as MuiTypography,
  Breadcrumbs as MuiBreadcrumbs,
  Autocomplete as MuiAutocomplete,
  InputAdornment as MuiInputAdornment,
} from "@mui/material";
import {
  Delete as MuiDeleteIcon,
  Warning as MuiWarningIcon,
  AddCircle as MuiAddCircleIcon,
  ArrowBack as MuiArrowBackIcon,
  CheckCircle as MuiCheckCircleIcon,
  NavigateNext as MuiNavigateNextIcon,
} from "@mui/icons-material";
import { AdapterDateFns as MuiAdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import {
  DatePicker as MuiDatePicker,
  LocalizationProvider as MuiLocalizationProvider,
} from "@mui/x-date-pickers";
import { Theme, useAlerts, useUsers } from "common";
import {
  getExpenditureRestrictionsByMatch,
  getPIOrComponentOwnerByMatch,
  getComponentById,
  postComponent,
  putComponent,
  getCommitmentNameById,
  getAwardDetails,
  getProjectDetails,
  getTaskDetails,
  getAwardAutocomplete,
  getProjectAutocomplete,
  getTaskAutocomplete,
} from "app/services/componentsService";
import { ComponentTypes, MovedStatus } from "app/shared/constants";
import { FormButton } from "app/shared/ui/FormButton";
import { CurrencyFormat } from "app/shared/ui/CurrencyFormat";
import { ConfirmationDialog } from "app/shared/ui/ConfirmationDialog";
import { disabledTextFieldStyles } from "app/shared/ui/sharedStyles";
import { ListPopover } from "./ListPopover";

export const CommitmentComponentForm = (props) => {
  // `useTranslation` will provide the Locale translation of text labels
  const { t } = useTranslation();
  const { currentUser } = useUsers();
  const { permissions } = currentUser;
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { clearAlert, setAlert } = useAlerts();

  const viewOnly = props.pageType === "view";
  const otherParent = props.parent === "other";
  const returnRoute = location.pathname.split("/").filter(Boolean)[0];

  const handleClose = () => {
    navigate(`/${returnRoute}`);
  };

  useEffect(() => {
    if (location.state) {
      const { successMessage } = location.state;
      setTimeout(() => {
        setAlert("success", successMessage, true);
      }, "1000");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const _handleSubmit = (values, setFieldError, setSubmitting) => {
    const args = [
      values,
      piOrComponentOwnerValue,
      setLoading,
      params.commitmentId,
      params.componentId,
      commitmentName,
      setFieldError,
      setSubmitting,
      clearAlert,
      setAlert,
      navigate,
      t,
      returnRoute,
    ];
    params.componentId ? putComponent(...args) : postComponent(...args);
  };

  const shrinkProps = viewOnly && { shrink: true };

  const componentFormField =
    "Commitments.commitmentComponents.create.form.fields";
  const commitmentComponentCreateStr =
    "Commitments.commitmentComponents.create";
  const commitmentComponentViewStr = "Commitments.commitmentComponents.view";
  const commitmentComponentEditStr = "Commitments.commitmentComponents.edit";
  const commitmentComponentList =
    "Commitments.commitmentComponents.mainView.list";

  // State variable and its function to maintain commitment name to show in title
  const [commitmentName, setCommitmentName] = useState("");
  const [awardEvent, setAwardEvent] = useState();
  const [projectEvent, setProjectEvent] = useState();
  const [taskEvent, setTaskEvent] = useState();
  // Custom hooks for getting detail for Award, Project and Task.
  const [awardDetails, setAwardDetails] = useState([]);
  const [projectDetails, setProjectDetails] = useState([]);
  const [taskDetails, setTaskDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [awardList, setAwardList] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [taskList, setTaskList] = useState([]);
  // Custom hooks for Award validation
  const [validateAward, setValidateAward] = useState();
  // Custom hooks for Project validation
  const [validateProject, setValidateProject] = useState();
  // Custom hooks for task validation
  const [validateTask, setValidateTask] = useState();
  // State variable to show/hide confirmation dialog for piOrComponentOwner change action
  const [showChangeOwnerDialog, setShowChangeOwnerDialog] = useState(false);
  const [piOrComponentOwnerValue, setPiOrComponentOwnerValue] = useState("");
  const [ptaTaskName, setPtaTaskName] = useState("");
  // State variable and its function for orgOptions
  const [expenditureRestrictions, setExpenditureRestrictions] = useState([]);
  // State variable and its function for piOrComponentOwner
  const [piOrComponentOwner, setPiOrComponentOwner] = useState([]);
  // State variable and its function to maintain loading indicator of PIOrComponentOwner data
  const [loadingPIOrComponentOwner, setLoadingPIOrComponentOwner] =
    useState(false);
  // State variable and its function to maintain closed notes and closed details view
  const [closed, setClosed] = useState(false);
  // State to handle start and expiration date errors
  const [startDateError, setStartDateError] = useState(false);
  const [expirationDateError, setExpirationDateError] = useState(false);
  // State to handle incremental component date errors
  const [incrementalDateErrors, setIncrementalDateErrors] = useState([]); // Array of errors
  // State to store form data response
  const [formDataResponse, setFormDataResponse] = useState({
    // Object keys use to create/edit form data
    componentName /* Component Name */: "",
    componentDescription /* Component Description */: "",
    componentIncrementals: [
      {
        componentDate: new Date(),
        componentAmount: 0,
      },
    ],
    project /* Project */: "",
    task /* Task */: "",
    award /* Award */: "",
    piOrComponentOwner /* PI/ComponentOwner */: "",
    componentType /* Component Type */: null,
    purpose1 /* Purpose 1 */: "",
    purpose2 /* Purpose 2 */: "",
    expenditures /* Expenditure Restrictions */: [],
    startDate /* Start Date */: null,
    expirationDate /* Expiration Date */: null,
    durationNotes /* Duration Notes */: "",
    closed /* Closed */: false,
    closedDate /* Closed Date */: null,
    closedDetails /* Closed Details */: "",
    unspentComponent /* Outstanding Component */: 0,
  });

  // Validation schema
  const commitmentValidation = Yup.object().shape({
    componentName: Yup.string().required(
      t(`${componentFormField}.componentName.validation.isEmpty`)
    ),
    componentDescription: Yup.string().required(
      t(`${componentFormField}.componentDescription.validation.isEmpty`)
    ),
    componentIncrementals: Yup.array().of(
      Yup.object().shape({
        componentDate: Yup.date()
          .required(t(`${componentFormField}.componentDate.validation.isEmpty`))
          .typeError(
            t(`${componentFormField}.componentDate.validation.invalidDate`)
          ),
        componentAmount: Yup.mixed().required(
          t(`${componentFormField}.totalComponentAmount.validation.isEmpty`)
        ),
      })
    ),
    closedDate: Yup.date().when("closed", {
      is: true,
      then: (schema) =>
        schema
          .required(t(`${componentFormField}.closedDate.validation.isEmpty`))
          .typeError(
            t(`${componentFormField}.closedDate.validation.invalidDate`)
          ),
      otherwise: (schema) => schema.nullable(),
    }),
    closedDetails: Yup.string().when("closed", {
      is: true,
      then: (schema) =>
        schema.required(
          t(`${componentFormField}.closedDetails.validation.isEmpty`)
        ),
      otherwise: (schema) => schema.nullable(),
    }),
    expenditures: Yup.array().max(
      5,
      t(
        `${componentFormField}.expenditureRestrictions.validation.maxExpenditures`
      )
    ),
  });

  // Create a list of values that excludes 'DEAN_COMMITMENT & REQUEST'
  const componentTypeMap = Object.assign(ComponentTypes);
  delete componentTypeMap.DEAN_COMMITMENT;
  delete componentTypeMap.REQUEST;
  const componentTypeValues = Object.values(componentTypeMap);

  useEffect(() => {
    getCommitmentNameById(
      setCommitmentName,
      params.commitmentId,
      setAlert,
      clearAlert
    );
    // eslint-disable-next-line
  }, [params.commitmentId]);

  useEffect(() => {
    params.componentId &&
      getComponentById(
        setFormDataResponse,
        setClosed,
        setPiOrComponentOwnerValue,
        setValidateAward,
        setValidateProject,
        setValidateTask,
        setPtaTaskName,
        params.commitmentId,
        params.componentId,
        setAlert,
        clearAlert
      );
    // eslint-disable-next-line
  }, [params.commitmentId, params.componentId]);

  const addMoreComponent = (values, setFieldValue) => {
    const initialValue = {
      componentDate: null,
      componentAmount: "0.00",
    };
    const appendedComponentValue = [...values, initialValue];

    setFieldValue("componentIncrementals", appendedComponentValue);
  };

  const removeComponent = (values, idx, setFieldValue) => {
    const filteredComponentValue = values.filter((_, index) => index !== idx);

    setFieldValue("componentIncrementals", filteredComponentValue);

    setIncrementalDateErrors((prevErrors) => {
      const newIncrementalDateErrors = prevErrors.filter(
        (_, index) => index !== idx
      );
      return newIncrementalDateErrors;
    });
  };

  // Check if there are any errors in the incrementalDateErrors array
  const hasIncrementalDateErrors = incrementalDateErrors.some(
    (error) => error !== null && error !== undefined && error !== ""
  );

  const getComponentDetails = (value, status = "", key = "") => {
    const { movedFromComponent, movedToComponent } = value;

    if (status === MovedStatus.MOVED) {
      const { commitment, id: componentId, componentName } = movedFromComponent;
      const newValue = {
        commitmentId: commitment.id,
        componentId,
        componentName,
      };

      return newValue[key];
    }

    const { commitment, id: componentId, componentName } = movedToComponent;
    const newValue = {
      commitmentId: commitment.id,
      componentId,
      componentName,
    };

    return newValue[key];
  };

  return (
    ((props.pageType === "new" && permissions.CREATE_COMPONENTS) ||
      ((props.pageType === "edit" || props.pageType === "newTab") &&
        permissions.EDIT_COMPONENTS) ||
      (props.pageType === "view" && permissions.VIEW_COMPONENTS)) && (
      <MuiGrid container>
        {props.pageType !== "newTab" && (
          <MuiGrid container item xs={12}>
            <MuiGrid item container xs={6} justifyContent="flex-start">
              <MuiBreadcrumbs
                separator={<MuiNavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
              >
                <RouterLink
                  to={`/commitments`}
                  style={{ textDecoration: "none" }}
                >
                  <MuiTypography color="textSecondary" variant="body2">
                    {t("Commitments.mainView.title")}
                  </MuiTypography>
                </RouterLink>
                <MuiTypography color="textPrimary" variant="subtitle1">
                  {props.pageType === "new"
                    ? t(`${commitmentComponentCreateStr}.breadcrumbTitle`)
                    : viewOnly
                    ? t(`${commitmentComponentViewStr}.breadcrumbTitle`)
                    : t(`${commitmentComponentEditStr}.breadcrumbTitle`)}
                </MuiTypography>
              </MuiBreadcrumbs>
            </MuiGrid>
            {viewOnly && !otherParent && (
              <MuiGrid
                item
                container
                xs={4}
                justifyContent="flex-end"
                spacing={2}
              >
                <RouterLink
                  style={{ textDecoration: "none" }}
                  to={`/${returnRoute}`}
                >
                  <MuiButton startIcon={<MuiArrowBackIcon />}>
                    {t("Commitments.view.goBackButtonLabel")}
                  </MuiButton>
                </RouterLink>
              </MuiGrid>
            )}
          </MuiGrid>
        )}
        <MuiGrid item xs={12} sx={{ padding: "8px 0" }}>
          <MuiTypography variant="h1">
            {props.pageType === "new"
              ? t(`${commitmentComponentCreateStr}.title`, {
                  commitmentName: commitmentName,
                })
              : viewOnly
              ? t(`${commitmentComponentViewStr}.title`, {
                  commitmentName: commitmentName,
                })
              : t(`${commitmentComponentEditStr}.title`, {
                  commitmentName: commitmentName,
                })}
          </MuiTypography>
        </MuiGrid>
        {/* Formik - Wrapper of library `Formik` which set/reset/submit form values
        to create/edit a commitment Component*/}
        <Formik
          // initialValues - User to store the Formik form's initial form values
          /** !Object */ initialValues={formDataResponse}
          /** !Boolean */ enableReinitialize
          // onSubmit - Callback definition to execute on the click of Form Submit
          onSubmit={(values, { setSubmitting, setFieldError }) => {
            setSubmitting(false);
            _handleSubmit(values, setFieldError, setSubmitting);
          }}
          validationSchema={commitmentValidation}
        >
          {(formikProps) => {
            const /** !Object */ {
                values,
                dirty,
                isValid,
                touched,
                errors,
                setValues,
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
                setFieldError,
              } = formikProps;
            return (
              // Native form element to submit the form values
              <MuiGrid item xs={12}>
                <form onSubmit={handleSubmit}>
                  {/* FormControlContainer - Flex container to wrap all the form flex items */}
                  <MuiGrid item container xs={10} direction="column" gap="16px">
                    <MuiGrid item xs={10} container justifyContent="flex-end">
                      <MuiGrid item xs={12}>
                        <MuiTypography align="right">
                          {values?.moveStatus && (
                            <Trans
                              i18nKey={
                                values.moveStatus === MovedStatus.MOVED
                                  ? "Commitments.moveComponent.movedStatusComponent"
                                  : "Commitments.moveComponent.originalStatusComponent"
                              }
                              values={{
                                componentName: getComponentDetails(
                                  values,
                                  values.moveStatus,
                                  "componentName"
                                ),
                                moveDate: format(
                                  new Date(values.moveDate),
                                  "MM/dd/yyyy"
                                ),
                                moveBy: values?.moveBy ?? "",
                              }}
                              components={{
                                Link: (
                                  <RouterLink
                                    to={{
                                      pathname: `/commitments/${getComponentDetails(
                                        values,
                                        values.moveStatus,
                                        "commitmentId"
                                      )}/components/${getComponentDetails(
                                        values,
                                        values.moveStatus,
                                        "componentId"
                                      )}/${props?.pageType}`,
                                    }}
                                  />
                                ),
                              }}
                            />
                          )}
                        </MuiTypography>
                      </MuiGrid>
                      <MuiGrid item xs={12}>
                        <MuiTypography align="right">
                          {t(`${componentFormField}.totalComponent.label`)}
                          {`: `}
                          {CurrencyFormat(values.totalComponentAmount ?? 0)}
                        </MuiTypography>
                      </MuiGrid>
                      <MuiGrid item xs={12}>
                        <MuiTypography align="right">
                          {t(`${componentFormField}.unspentComponent.label`)}
                          {`: `}
                          {!values.closed &&
                            CurrencyFormat(values.unspentComponent)}
                          {values.closed && CurrencyFormat(0)}
                        </MuiTypography>
                      </MuiGrid>
                    </MuiGrid>
                    <MuiGrid
                      item
                      container
                      xs={10}
                      justifyContent="space-between"
                      spacing={2}
                    >
                      {/* Component Name - Multiline Textfield Input */}
                      <MuiGrid item xs={12}>
                        <MuiTextField
                          id="componentName"
                          name="componentName"
                          label={t(`${componentFormField}.componentName.label`)}
                          required={!viewOnly}
                          value={values.componentName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          multiline
                          variant={viewOnly ? "standard" : "outlined"}
                          autoComplete="off"
                          fullWidth
                          inputProps={{ maxLength: 250 }}
                          helperText={
                            touched.componentName ? errors.componentName : ""
                          }
                          error={
                            touched.componentName &&
                            Boolean(errors.componentName)
                          }
                          disabled={viewOnly}
                          sx={disabledTextFieldStyles}
                        />
                      </MuiGrid>
                    </MuiGrid>
                    <MuiGrid
                      item
                      container
                      xs={10}
                      justifyContent="space-between"
                      spacing={2}
                    >
                      {/* Component Description - Multiline Textfield Input */}
                      <MuiGrid item xs={12}>
                        <MuiTextField
                          id="componentDescription"
                          name="componentDescription"
                          label={t(
                            `${componentFormField}.componentDescription.label`
                          )}
                          required={!viewOnly}
                          value={values.componentDescription}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          multiline
                          variant={viewOnly ? "standard" : "outlined"}
                          autoComplete="off"
                          fullWidth
                          inputProps={{ maxLength: 250 }}
                          helperText={
                            touched.componentDescription
                              ? errors.componentDescription
                              : ""
                          }
                          error={
                            touched.componentDescription &&
                            Boolean(errors.componentDescription)
                          }
                          disabled={viewOnly}
                          sx={disabledTextFieldStyles}
                        />
                      </MuiGrid>
                    </MuiGrid>
                    <MuiGrid
                      item
                      container
                      xs={10}
                      justifyContent="space-between"
                      spacing={2}
                    >
                      {values?.componentIncrementals?.map((component, idx) => {
                        return (
                          <MuiGrid
                            item
                            container
                            justifyContent="space-between"
                            spacing={2}
                            key={idx}
                          >
                            <MuiGrid item xs={6}>
                              {/* Incremental Component Date - DatePicker Input */}
                              <MuiLocalizationProvider
                                dateAdapter={MuiAdapterDateFns}
                              >
                                <MuiDatePicker
                                  id={`componentIncrementals[${idx}]componentDate`}
                                  name={`componentIncrementals[${idx}]componentDate`}
                                  format="MM/dd/yyyy"
                                  label={t(
                                    `${componentFormField}.componentDate.label`
                                  )}
                                  disabled={viewOnly}
                                  clearable
                                  sx={disabledTextFieldStyles}
                                  value={component.componentDate}
                                  onChange={(newValue) => {
                                    setFieldValue(
                                      `componentIncrementals[${idx}].componentDate`,
                                      newValue
                                    );
                                  }}
                                  onError={(reason) => {
                                    setIncrementalDateErrors((prevErrors) => {
                                      const newIncrementalDateErrors = [
                                        ...prevErrors,
                                      ];
                                      newIncrementalDateErrors[idx] = reason;
                                      return newIncrementalDateErrors;
                                    });
                                  }}
                                  minDate={
                                    values?.componentIncrementals?.[idx - 1]
                                      ?.componentDate
                                      ? addDays(
                                          values.componentIncrementals[idx - 1]
                                            .componentDate,
                                          1
                                        )
                                      : null
                                  }
                                  slotProps={{
                                    textField: {
                                      fullWidth: true,
                                      onBlur: handleBlur,
                                      required: !viewOnly,
                                      variant: viewOnly
                                        ? "standard"
                                        : "outlined",
                                      error:
                                        Boolean(
                                          touched?.componentIncrementals?.[idx]
                                            ?.componentDate
                                        ) &&
                                        (Boolean(
                                          errors?.componentIncrementals?.[idx]
                                            ?.componentDate
                                        ) ||
                                          Boolean(incrementalDateErrors[idx])),
                                      // check if the field is touched and if there is an formik error then show the formik error,
                                      // or if the min date error is true then show the min date error
                                      helperText: Boolean(
                                        touched?.componentIncrementals?.[idx]
                                          ?.componentDate
                                      )
                                        ? incrementalDateErrors[idx] ===
                                          "minDate"
                                          ? t(
                                              `${componentFormField}.componentDate.validation.minDateMessage`
                                            )
                                          : errors?.componentIncrementals?.[idx]
                                              ?.componentDate
                                        : "",
                                    },
                                  }}
                                />
                              </MuiLocalizationProvider>
                            </MuiGrid>
                            <MuiGrid item xs={5}>
                              {/* Total Component Amount - Textfield Input */}
                              <NumericFormat
                                id={`componentIncrementals[${idx}]componentAmount`}
                                name={`componentIncrementals[${idx}]componentAmount`}
                                onBlur={handleBlur}
                                label={t(
                                  `${componentFormField}.totalComponentAmount.label`
                                )}
                                required={!viewOnly}
                                thousandSeparator={true}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                customInput={MuiTextField}
                                isAllowed={(values) => {
                                  const { value } = values;
                                  return value <= 999999999.99;
                                }}
                                allowNegative={true}
                                InputProps={{
                                  startAdornment: (
                                    <MuiInputAdornment position="start">
                                      $
                                    </MuiInputAdornment>
                                  ),
                                }}
                                value={component.componentAmount}
                                onValueChange={(value, sourceInfo) => {
                                  setFieldValue(
                                    `componentIncrementals[${idx}].componentAmount`,
                                    value?.floatValue
                                  );
                                }}
                                variant={viewOnly ? "standard" : "outlined"}
                                autoComplete="off"
                                fullWidth={true}
                                sx={disabledTextFieldStyles}
                                disabled={viewOnly}
                                InputLabelProps={{ ...shrinkProps }}
                                helperText={
                                  Boolean(
                                    touched?.componentIncrementals?.[idx]
                                      ?.componentAmount
                                  )
                                    ? errors?.componentIncrementals?.[idx]
                                        ?.componentAmount
                                    : ""
                                }
                                error={
                                  Boolean(
                                    touched?.componentIncrementals?.[idx]
                                      ?.componentAmount
                                  ) &&
                                  Boolean(
                                    errors?.componentIncrementals?.[idx]
                                      ?.componentAmount
                                  )
                                }
                              />
                            </MuiGrid>
                            {viewOnly ? null : (
                              <MuiGrid
                                item
                                container
                                justifyContent="flex-end"
                                alignItems="center"
                                xs={1}
                              >
                                {values.componentIncrementals.length ===
                                  idx + 1 && (
                                  <MuiIconButton
                                    aria-label="Add"
                                    onClick={() =>
                                      addMoreComponent(
                                        values.componentIncrementals,
                                        setFieldValue
                                      )
                                    }
                                    disabled={
                                      values.componentIncrementals.length === 10
                                    }
                                    sx={{
                                      "&.Mui-disabled": {
                                        pointerEvents: "auto",
                                      },
                                    }}
                                  >
                                    <MuiTooltip
                                      title={
                                        values.componentIncrementals.length ===
                                        10
                                          ? t(
                                              `${componentFormField}.addComponentMaxLimit.label`
                                            )
                                          : t(
                                              `${componentFormField}.addComponent.label`
                                            )
                                      }
                                    >
                                      <MuiAddCircleIcon />
                                    </MuiTooltip>
                                  </MuiIconButton>
                                )}
                                <MuiIconButton
                                  sx={{
                                    margin: "10px 0 ",
                                  }}
                                  aria-label="delete"
                                  disabled={
                                    values.componentIncrementals.length === 1
                                  }
                                  onClick={() =>
                                    removeComponent(
                                      values.componentIncrementals,
                                      idx,
                                      setFieldValue
                                    )
                                  }
                                >
                                  <MuiTooltip
                                    title={t(
                                      `${componentFormField}.deleteComponent.label`
                                    )}
                                  >
                                    <MuiDeleteIcon />
                                  </MuiTooltip>
                                </MuiIconButton>
                              </MuiGrid>
                            )}
                          </MuiGrid>
                        );
                      })}
                    </MuiGrid>
                    <MuiGrid
                      item
                      container
                      xs={10}
                      justifyContent="space-between"
                      spacing={2}
                    >
                      <MuiGrid item xs={6} container>
                        {/* Project - Textfield Input */}
                        <MuiGrid item xs={4}>
                          <MuiAutocomplete
                            id="project"
                            name="project"
                            value={
                              values.award && values.project
                                ? values.project.trim()
                                : ""
                            }
                            onChange={(event, value) => {
                              const projectValue = value || "";
                              setFieldValue("project", projectValue);
                              getProjectDetails(
                                values.award,
                                projectValue,
                                setProjectDetails,
                                setValidateProject
                              );
                            }}
                            onBlur={handleBlur}
                            getOptionLabel={(option) => option}
                            options={projectList || []}
                            loading={loading}
                            label={t(`${componentFormField}.project.label`)}
                            variant="outlined"
                            freeSolo
                            disabled={viewOnly || !values.award}
                            renderInput={(params) => (
                              <MuiTextField
                                {...params}
                                label={t(`${componentFormField}.project.label`)}
                                autoComplete="off"
                                variant={viewOnly ? "standard" : "outlined"}
                                InputLabelProps={{ ...shrinkProps }}
                                helperText={
                                  touched.project ? errors.project : ""
                                }
                                error={
                                  touched.project && Boolean(errors.project)
                                }
                                onChange={(e, value) => {
                                  setFieldValue("project", e.target.value);
                                  if (e.target.value === "") {
                                    setValidateProject();
                                    setValidateTask();
                                    setFieldValue("task", "");
                                  }
                                  getProjectAutocomplete(
                                    e.target.value,
                                    values.award,
                                    setProjectList,
                                    setLoading,
                                    setFieldError
                                  );
                                  getProjectDetails(
                                    values.award,
                                    e.target.value,
                                    setProjectDetails,
                                    setValidateProject
                                  );
                                }}
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <>
                                      {validateProject === "EXIST" && (
                                        <MuiInputAdornment position="end">
                                          <MuiCheckCircleIcon
                                            sx={{
                                              color: Theme.palette.success.main,
                                            }}
                                            onMouseEnter={(e) => {
                                              e.persist();
                                              setProjectEvent(e);
                                              getProjectDetails(
                                                values.award,
                                                values.project,
                                                setProjectDetails,
                                                setValidateProject
                                              );
                                            }}
                                          />
                                        </MuiInputAdornment>
                                      )}
                                      {validateProject === "NOTEXIST" && (
                                        <MuiInputAdornment position="end">
                                          <MuiWarningIcon
                                            sx={{
                                              color: Theme.palette.warning.main,
                                            }}
                                          />
                                        </MuiInputAdornment>
                                      )}
                                    </>
                                  ),
                                }}
                                inputProps={{
                                  ...params.inputProps,
                                  maxLength: 7,
                                }}
                                sx={{
                                  paddingRight: "8px",
                                  ...disabledTextFieldStyles,
                                }}
                              />
                            )}
                          />
                          {projectDetails && (
                            <ListPopover
                              data={projectDetails}
                              entity="project"
                              entityValue={values.project}
                              header={t(`${componentFormField}.project.label`)}
                              trigger={projectEvent}
                              setListEvent={setProjectEvent}
                            />
                          )}
                        </MuiGrid>
                        <MuiGrid item xs={4}>
                          {/* Task - Textfield Input */}
                          <MuiAutocomplete
                            id="task"
                            name="task"
                            value={
                              values.award && values.project && values.task
                                ? values.task.trim()
                                : ""
                            }
                            onBlur={handleBlur}
                            onChange={(event, value) => {
                              const taskValue = value || "";
                              setFieldValue("task", taskValue);
                              getTaskDetails(
                                values.award,
                                values.project,
                                taskValue,
                                setTaskDetails,
                                setValidateTask,
                                setPtaTaskName,
                                piOrComponentOwnerValue,
                                setPiOrComponentOwnerValue,
                                setShowChangeOwnerDialog,
                                true
                              );
                            }}
                            getOptionLabel={(option) => option}
                            options={taskList || []}
                            loading={loading}
                            label={t(`${componentFormField}.task.label`)}
                            variant="outlined"
                            freeSolo
                            clearIcon={false}
                            disabled={
                              viewOnly ||
                              (!values.project && true) ||
                              (!values.award && true)
                            }
                            renderInput={(params) => (
                              <MuiTextField
                                {...params}
                                label={t(`${componentFormField}.task.label`)}
                                autoComplete="off"
                                sx={{
                                  paddingRight: "8px",
                                  ...disabledTextFieldStyles,
                                }}
                                variant={viewOnly ? "standard" : "outlined"}
                                InputLabelProps={{ ...shrinkProps }}
                                helperText={touched.task ? errors.task : ""}
                                error={touched.task && Boolean(errors.task)}
                                onChange={(e, value) => {
                                  e.target.value === "" && setValidateTask();
                                  setFieldValue("task", e.target.value);
                                  getTaskAutocomplete(
                                    e.target.value,
                                    values.award,
                                    values.project,
                                    setTaskList,
                                    setLoading,
                                    setFieldError
                                  );
                                  getTaskDetails(
                                    values.award,
                                    values.project,
                                    e.target.value,
                                    setTaskDetails,
                                    setValidateTask,
                                    setPtaTaskName,
                                    piOrComponentOwnerValue,
                                    setPiOrComponentOwnerValue,
                                    setShowChangeOwnerDialog,
                                    true
                                  );
                                }}
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <>
                                      {validateTask === "EXIST" && (
                                        <MuiInputAdornment position="end">
                                          <MuiCheckCircleIcon
                                            sx={{
                                              color: Theme.palette.success.main,
                                            }}
                                            onMouseEnter={(e) => {
                                              e.persist();
                                              setTaskEvent(e);
                                              getTaskDetails(
                                                values.award,
                                                values.project,
                                                values.task,
                                                setTaskDetails,
                                                setValidateTask,
                                                setPtaTaskName,
                                                piOrComponentOwnerValue,
                                                setPiOrComponentOwnerValue,
                                                setShowChangeOwnerDialog,
                                                false
                                              );
                                            }}
                                          />
                                        </MuiInputAdornment>
                                      )}
                                      {validateTask === "NOTEXIST" && (
                                        <MuiInputAdornment position="end">
                                          <MuiWarningIcon
                                            sx={{
                                              color: Theme.palette.warning.main,
                                            }}
                                          />
                                        </MuiInputAdornment>
                                      )}
                                    </>
                                  ),
                                }}
                              />
                            )}
                          />
                          {taskDetails && (
                            <ListPopover
                              data={taskDetails}
                              entity="task"
                              entityValue={values.task}
                              header={t(`${componentFormField}.task.label`)}
                              trigger={taskEvent}
                              setListEvent={setTaskEvent}
                            />
                          )}
                        </MuiGrid>
                        <MuiGrid item xs={4}>
                          {/* Project - Textfield Input */}
                          <MuiAutocomplete
                            id="award"
                            name="award"
                            value={values.award ? values.award.trim() : ""}
                            onBlur={handleBlur}
                            onChange={(event, value) => {
                              const awardValue = value || "";
                              setFieldValue("award", awardValue);
                              getAwardDetails(
                                awardValue,
                                setAwardDetails,
                                setValidateAward
                              );
                            }}
                            getOptionLabel={(option) => option}
                            options={awardList || []}
                            loading={loading}
                            variant="outlined"
                            disabled={viewOnly}
                            freeSolo
                            clearIcon={false}
                            renderInput={(params) => (
                              <MuiTextField
                                {...params}
                                label={t(`${componentFormField}.award.label`)}
                                autoComplete="off"
                                sx={disabledTextFieldStyles}
                                variant={viewOnly ? "standard" : "outlined"}
                                InputLabelProps={{ ...shrinkProps }}
                                helperText={touched.award ? errors.award : ""}
                                error={touched.award && Boolean(errors.award)}
                                onChange={(e, value) => {
                                  setFieldValue("award", e.target.value);
                                  if (e.target.value === "") {
                                    setValidateAward();
                                    setValidateProject();
                                    setValidateTask();
                                    setFieldValue("award", "");
                                    setFieldValue("task", "");
                                    setFieldValue("project", "");
                                  }
                                  getAwardAutocomplete(
                                    e.target.value,
                                    setAwardList,
                                    setLoading,
                                    setFieldError
                                  );
                                  getAwardDetails(
                                    e.target.value,
                                    setAwardDetails,
                                    setValidateAward
                                  );
                                }}
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <>
                                      {validateAward === "EXIST" && (
                                        <MuiInputAdornment position="end">
                                          <MuiCheckCircleIcon
                                            sx={{
                                              color: Theme.palette.success.main,
                                            }}
                                            onMouseEnter={(e) => {
                                              e.persist();
                                              // Setting awardNumber param
                                              setAwardEvent(e);
                                              getAwardDetails(
                                                values.award,
                                                setAwardDetails,
                                                setValidateAward
                                              );
                                            }}
                                          />
                                        </MuiInputAdornment>
                                      )}
                                      {validateAward === "NOTEXIST" && (
                                        <MuiInputAdornment position="end">
                                          <MuiWarningIcon
                                            sx={{
                                              color: Theme.palette.warning.main,
                                            }}
                                          />
                                        </MuiInputAdornment>
                                      )}
                                    </>
                                  ),
                                }}
                                inputProps={{
                                  ...params.inputProps,
                                  maxLength: 5,
                                }}
                              />
                            )}
                          />
                          {awardDetails && (
                            <ListPopover
                              data={awardDetails}
                              entity="award"
                              entityValue={values.award}
                              header={t(`${componentFormField}.award.label`)}
                              trigger={awardEvent}
                              setListEvent={setAwardEvent}
                            />
                          )}
                        </MuiGrid>
                      </MuiGrid>
                      <MuiGrid item xs={6}>
                        {/* Task Name - Textfield Input */}
                        {values.award &&
                        values.project &&
                        values.task &&
                        validateAward === "EXIST" &&
                        validateProject === "EXIST" &&
                        validateTask === "EXIST" ? (
                          <MuiTextField
                            id="taskName"
                            name="taskName"
                            label={t(`${componentFormField}.taskName.label`)}
                            fullWidth
                            value={ptaTaskName}
                            disabled
                            InputLabelProps={{ ...shrinkProps }}
                            sx={disabledTextFieldStyles}
                          />
                        ) : (
                          ""
                        )}
                      </MuiGrid>
                    </MuiGrid>
                    <MuiGrid
                      item
                      container
                      xs={10}
                      justifyContent="space-between"
                      spacing={2}
                    >
                      <MuiGrid item xs={6}>
                        {/* piOrComponentOwner - Autocomplete Input */}
                        <MuiAutocomplete
                          id="piOrComponentOwner"
                          name="piOrComponentOwner"
                          options={piOrComponentOwner || []}
                          loading={loadingPIOrComponentOwner}
                          freeSolo
                          loadingText={t(
                            `${commitmentComponentCreateStr}.form.fieldDataLoadingText`
                          )}
                          getOptionLabel={(option) =>
                            option.displayText ? option.displayText : ""
                          }
                          value={piOrComponentOwnerValue}
                          onChange={(event, value) => {
                            setPiOrComponentOwnerValue(value);
                            setFieldValue("piOrComponentOwner", value);
                          }}
                          disabled={viewOnly}
                          onInputChange={(event, value) => {
                            // to allow only on synthetic events
                            if (event) {
                              const cancelSource = axios.CancelToken.source();

                              getPIOrComponentOwnerByMatch(
                                value,
                                () => {
                                  //onBeforeSend
                                  setFieldError("piOrComponentOwner");
                                  setLoadingPIOrComponentOwner(true);
                                },
                                () => {
                                  //onError
                                  setLoadingPIOrComponentOwner(false);
                                },
                                (responseData) => {
                                  //onSuccess
                                  setPiOrComponentOwner(
                                    responseData.values || []
                                  );
                                  setLoadingPIOrComponentOwner(false);
                                },
                                cancelSource
                              );

                              setFieldValue("piOrComponentOwner", value);
                            }
                          }}
                          clearIcon={false}
                          renderInput={(params) => {
                            return (
                              <MuiTextField
                                {...params}
                                label={t(
                                  `${componentFormField}.piOrComponentOwner.label`
                                )}
                                variant={viewOnly ? "standard" : "outlined"}
                                InputLabelProps={{ ...shrinkProps }}
                                sx={disabledTextFieldStyles}
                                helperText={
                                  touched.piOrComponentOwner
                                    ? errors.piOrComponentOwner
                                    : ""
                                }
                                error={
                                  touched.piOrComponentOwner &&
                                  Boolean(errors.piOrComponentOwner)
                                }
                                InputProps={{
                                  ...params.InputProps,
                                  onBlur: (event) => {
                                    setPiOrComponentOwnerValue({
                                      displayText: event.target.value,
                                    });
                                  },
                                }}
                              />
                            );
                          }}
                        />
                        {taskDetails.taskOwnerFullName &&
                          piOrComponentOwnerValue && (
                            <ConfirmationDialog
                              open={showChangeOwnerDialog}
                              title={t(
                                `${commitmentComponentCreateStr}.changePIOrComponentOwnerPopup.title`
                              )}
                              message={t(
                                `${commitmentComponentCreateStr}.changePIOrComponentOwnerPopup.message`,
                                {
                                  taskOwnerName: taskDetails.taskOwnerFullName,
                                  componentOwnerName:
                                    piOrComponentOwnerValue.displayText,
                                }
                              )}
                              handleCancel={() => {
                                setShowChangeOwnerDialog(false);
                              }}
                              handleOk={() => {
                                setPiOrComponentOwnerValue({
                                  displayText: taskDetails.taskOwnerFullName,
                                });
                                setShowChangeOwnerDialog(false);
                              }}
                              okLabel={t(
                                `${commitmentComponentCreateStr}.changePIOrComponentOwnerPopup.okLabel`
                              )}
                              cancelLabel={t(
                                `${commitmentComponentCreateStr}.changePIOrComponentOwnerPopup.cancelLabel`
                              )}
                            />
                          )}
                      </MuiGrid>
                      <MuiGrid item xs={6}>
                        {/* Component Type - Autocomplete Input */}
                        <MuiAutocomplete
                          id="componentType"
                          name="componentType"
                          options={componentTypeValues}
                          getOptionLabel={(option) => t(option)}
                          isOptionEqualToValue={(option, value) =>
                            t(option) === value
                          }
                          value={
                            (values.componentType &&
                              t(ComponentTypes[values.componentType])) ||
                            null
                          }
                          disabled={viewOnly}
                          forcePopupIcon={!viewOnly}
                          onChange={(event, valueObj) => {
                            setFieldValue(
                              "componentType",
                              Object.keys(ComponentTypes).find(
                                (key) => ComponentTypes[key] === valueObj
                              )
                            );
                          }}
                          clearIcon={false}
                          renderInput={(params) => (
                            <MuiTextField
                              {...params}
                              label={t(
                                `${componentFormField}.componentType.label`
                              )}
                              variant={viewOnly ? "standard" : "outlined"}
                              InputLabelProps={{ ...shrinkProps }}
                              sx={disabledTextFieldStyles}
                            />
                          )}
                        />
                      </MuiGrid>
                    </MuiGrid>
                    <MuiGrid
                      item
                      container
                      xs={10}
                      justifyContent="space-between"
                      spacing={2}
                    >
                      <MuiGrid item xs={6}>
                        {/* Purpose1 - Multiline Textfield Input */}
                        <MuiTextField
                          id="purpose1"
                          label={t(`${componentFormField}.purpose1.label`)}
                          value={values.purpose1}
                          onChange={handleChange}
                          variant={viewOnly ? "standard" : "outlined"}
                          disabled={viewOnly}
                          autoComplete="off"
                          fullWidth
                          inputProps={{ maxLength: 50 }}
                          InputLabelProps={{ ...shrinkProps }}
                          sx={disabledTextFieldStyles}
                        />
                      </MuiGrid>
                      <MuiGrid item xs={6}>
                        {/* purpose2 - Multiline Textfield Input */}
                        <MuiTextField
                          id="purpose2"
                          label={t(`${componentFormField}.purpose2.label`)}
                          value={values.purpose2}
                          onChange={handleChange}
                          variant={viewOnly ? "standard" : "outlined"}
                          disabled={viewOnly}
                          autoComplete="off"
                          fullWidth
                          inputProps={{ maxLength: 50 }}
                          InputLabelProps={{ ...shrinkProps }}
                          sx={disabledTextFieldStyles}
                        />
                      </MuiGrid>
                    </MuiGrid>
                    <MuiGrid
                      item
                      container
                      xs={10}
                      justifyContent="space-between"
                      spacing={2}
                    >
                      {/* Expenditure Restrictions - Autocomplete Multiselect Input */}
                      <MuiGrid item xs={12}>
                        <MuiAutocomplete
                          multiple
                          id="expenditures"
                          name="expenditures"
                          options={expenditureRestrictions ?? []}
                          getOptionLabel={(option) =>
                            option && option.displayText
                          }
                          onBlur={handleBlur}
                          onChange={(event, valueObj) => {
                            setFieldValue("expenditures", valueObj ?? "");
                          }}
                          isOptionEqualToValue={(option, value) =>
                            option.displayText === value.displayText
                          }
                          disabled={viewOnly}
                          forcePopupIcon={!viewOnly}
                          onInputChange={(e, value) => {
                            getExpenditureRestrictionsByMatch(
                              setLoading,
                              setExpenditureRestrictions,
                              value,
                              setFieldError
                            );
                          }}
                          value={values.expenditures}
                          filterSelectedOptions
                          renderInput={(params) => (
                            <MuiTextField
                              {...params}
                              label={t(
                                `${componentFormField}.expenditureRestrictions.label`
                              )}
                              onBlur={handleBlur}
                              variant={viewOnly ? "standard" : "outlined"}
                              helperText={
                                touched.expenditures ? errors.expenditures : ""
                              }
                              error={
                                touched.expenditures &&
                                Boolean(errors.expenditures)
                              }
                              InputLabelProps={{ ...shrinkProps }}
                              sx={disabledTextFieldStyles}
                            />
                          )}
                        />
                      </MuiGrid>
                    </MuiGrid>
                    <MuiGrid
                      item
                      container
                      xs={10}
                      justifyContent="space-between"
                      spacing={2}
                    >
                      <MuiGrid item xs={6}>
                        {/* Closed Date - Date Picker Input */}
                        <MuiLocalizationProvider
                          dateAdapter={MuiAdapterDateFns}
                        >
                          <MuiDatePicker
                            id="startDate"
                            name="startDate"
                            format="MM/dd/yyyy"
                            label={t(`${componentFormField}.startDate.label`)}
                            value={values.startDate}
                            onChange={(value) => {
                              setFieldValue("startDate", value);
                            }}
                            onError={(reason) => {
                              if (reason === "maxDate") {
                                setStartDateError(
                                  t(
                                    `${componentFormField}.startDate.validation.maxDateMessage`
                                  )
                                );
                              } else if (reason === "invalidDate") {
                                setStartDateError(
                                  t(
                                    `${componentFormField}.startDate.validation.invalidDate`
                                  )
                                );
                              } else {
                                setStartDateError(reason);
                              }
                            }}
                            slotProps={{
                              textField: {
                                onBlur: handleBlur,
                                variant: viewOnly ? "standard" : "outlined",
                                error: Boolean(startDateError),
                                helperText: startDateError,
                              },
                            }}
                            maxDate={
                              values.expirationDate
                                ? subDays(values.expirationDate, 1)
                                : null
                            }
                            disabled={viewOnly}
                            sx={disabledTextFieldStyles}
                          />
                        </MuiLocalizationProvider>
                      </MuiGrid>
                      <MuiGrid item xs={6}>
                        {/* Closed Date - Date Picker Input */}
                        <MuiLocalizationProvider
                          dateAdapter={MuiAdapterDateFns}
                        >
                          <MuiDatePicker
                            id="expirationDate"
                            name="expirationDate"
                            format="MM/dd/yyyy"
                            label={t(
                              `${componentFormField}.expirationDate.label`
                            )}
                            value={values.expirationDate}
                            onChange={(value) => {
                              setFieldValue("expirationDate", value);
                            }}
                            onError={(reason) => {
                              if (reason === "minDate") {
                                setExpirationDateError(
                                  t(
                                    `${componentFormField}.expirationDate.validation.minDateMessage`
                                  )
                                );
                              } else if (reason === "invalidDate") {
                                setExpirationDateError(
                                  t(
                                    `${componentFormField}.expirationDate.validation.invalidDate`
                                  )
                                );
                              } else {
                                setExpirationDateError(reason);
                              }
                            }}
                            slotProps={{
                              textField: {
                                onBlur: handleBlur,
                                variant: viewOnly ? "standard" : "outlined",
                                error: Boolean(expirationDateError),
                                helperText: expirationDateError,
                              },
                            }}
                            minDate={
                              values.startDate
                                ? addDays(values.startDate, 1)
                                : null
                            }
                            disabled={viewOnly}
                            sx={disabledTextFieldStyles}
                          />
                        </MuiLocalizationProvider>
                      </MuiGrid>
                    </MuiGrid>
                    <MuiGrid
                      item
                      container
                      xs={10}
                      justifyContent="space-between"
                      spacing={2}
                    >
                      <MuiGrid item xs={6}>
                        {/* Duration Notes - Textfield Input */}
                        <MuiTextField
                          id="durationNotes"
                          label={t(`${componentFormField}.durationNotes.label`)}
                          value={values.durationNotes}
                          onChange={handleChange}
                          multiline
                          variant={viewOnly ? "standard" : "outlined"}
                          autoComplete="off"
                          fullWidth
                          inputProps={{ maxLength: 50 }}
                          disabled={viewOnly}
                          InputLabelProps={{ ...shrinkProps }}
                          sx={disabledTextFieldStyles}
                        />
                      </MuiGrid>
                      <MuiGrid item xs={6} />
                    </MuiGrid>
                    <MuiGrid
                      item
                      container
                      xs={10}
                      justifyContent="space-between"
                      spacing={2}
                    >
                      {/* Closed - Checkbox Input */}
                      {!viewOnly ? (
                        <MuiGrid item xs={6} container alignItems="center">
                          <MuiCheckbox
                            id="closed"
                            sx={{
                              color: Theme.palette.text.primary,
                            }}
                            color="default"
                            checked={values.closed}
                            onChange={(event) => {
                              setClosed(event.target.checked);
                              setValues({
                                ...values,
                                closed: event.target.checked,
                                closedDate: event.target.checked
                                  ? new Date()
                                  : null,
                              });
                            }}
                          />{" "}
                          &nbsp;{" "}
                          <MuiTypography display="inline">
                            {t(`${componentFormField}.closed.label`)}
                          </MuiTypography>
                        </MuiGrid>
                      ) : (
                        <MuiGrid item xs={6}>
                          <MuiTextField
                            label={t(
                              `${commitmentComponentList}.tableColumns.status`
                            )}
                            value={
                              values.closed
                                ? t(
                                    `${commitmentComponentList}.tableValues.statusClosedText`
                                  )
                                : t(
                                    `${commitmentComponentList}.tableValues.statusOpenText`
                                  )
                            }
                            disabled
                            fullWidth
                            sx={disabledTextFieldStyles}
                          />
                        </MuiGrid>
                      )}

                      {closed && (
                        <MuiGrid item xs={6}>
                          {/* Closed Date - Date Picker Input */}
                          <MuiLocalizationProvider
                            dateAdapter={MuiAdapterDateFns}
                          >
                            <MuiDatePicker
                              id="closedDate"
                              name="closedDate"
                              format="MM/dd/yyyy"
                              label={t(
                                `${componentFormField}.closedDate.label`
                              )}
                              value={values.closedDate}
                              onChange={(newValue) => {
                                setFieldValue("closedDate", newValue);
                              }}
                              slotProps={{
                                textField: {
                                  required: !viewOnly,
                                  onBlur: handleBlur,
                                  variant: viewOnly ? "standard" : "outlined",
                                  error: Boolean(errors.closedDate),
                                  helperText: errors.closedDate,
                                },
                              }}
                              clearable
                              disabled={viewOnly}
                              sx={disabledTextFieldStyles}
                            />
                          </MuiLocalizationProvider>
                        </MuiGrid>
                      )}
                    </MuiGrid>
                    {closed && (
                      <MuiGrid
                        item
                        container
                        xs={10}
                        justifyContent="space-between"
                        spacing={2}
                      >
                        <MuiGrid item xs={12}>
                          <MuiTextField
                            id="closedDetails"
                            name="closedDetails"
                            label={t(
                              `${componentFormField}.closedDetails.label`
                            )}
                            required={!viewOnly}
                            value={values.closedDetails}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            variant={viewOnly ? "standard" : "outlined"}
                            disabled={viewOnly}
                            autoComplete="off"
                            fullWidth
                            inputProps={{ maxLength: 100 }}
                            InputLabelProps={{ ...shrinkProps }}
                            sx={disabledTextFieldStyles}
                            error={
                              touched.closedDetails &&
                              Boolean(errors.closedDetails)
                            }
                            helperText={
                              touched.closedDetails ? errors.closedDetails : ""
                            }
                          />
                        </MuiGrid>
                      </MuiGrid>
                    )}
                    <MuiGrid
                      item
                      container
                      xs={10}
                      justifyContent="space-between"
                      spacing={2}
                    >
                      {/* Record Number - Textfield Input */}
                      <MuiGrid item xs={2}>
                        <MuiTextField
                          id="recordNumber"
                          variant="standard"
                          label={t(`${componentFormField}.recordNumber.label`)}
                          value={formDataResponse.recordNumber || ""}
                          disabled
                          sx={disabledTextFieldStyles}
                        />
                      </MuiGrid>
                    </MuiGrid>

                    {!viewOnly ? (
                      <MuiGrid
                        item
                        xs={10}
                        container
                        justifyContent="flex-end"
                        spacing={2}
                      >
                        <MuiGrid
                          container
                          item
                          justifyContent="flex-end"
                          xs={3}
                          spacing={2}
                        >
                          <FormButton
                            cancel={handleClose}
                            save={{
                              disabled:
                                !dirty || !isValid || hasIncrementalDateErrors,
                            }}
                          />
                        </MuiGrid>
                      </MuiGrid>
                    ) : (
                      !otherParent && (
                        <MuiGrid
                          item
                          xs={10}
                          container
                          justifyContent="flex-end"
                          spacing={2}
                        >
                          {/* Back Button */}
                          <MuiGrid container item justifyContent="flex-end">
                            <RouterLink
                              style={{ textDecoration: "none" }}
                              to={`/${returnRoute}`}
                            >
                              <MuiButton
                                variant="outlined"
                                size="large"
                                startIcon={<MuiArrowBackIcon />}
                              >
                                {t("Commitments.view.goBackButtonLabel")}
                              </MuiButton>
                            </RouterLink>
                          </MuiGrid>
                        </MuiGrid>
                      )
                    )}
                  </MuiGrid>
                </form>
              </MuiGrid>
            );
          }}
        </Formik>
      </MuiGrid>
    )
  );
};

CommitmentComponentForm.displayName = "CommitmentComponentForm";
