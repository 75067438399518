import { Fragment, useCallback, useEffect, useState } from "react";
import { format } from "date-fns";
import { Trans, useTranslation } from "react-i18next";
import { Link as RouterLink, useParams } from "react-router-dom";
import {
  Box as MuiBox,
  Grid as MuiGrid,
  List as MuiList,
  Paper as MuiPaper,
  Table as MuiTable,
  Button as MuiButton,
  Tooltip as MuiTooltip,
  Popover as MuiPopover,
  Collapse as MuiCollapse,
  ListItem as MuiListItem,
  TableRow as MuiTableRow,
  TableBody as MuiTableBody,
  TableCell as MuiTableCell,
  Typography as MuiTypography,
  IconButton as MuiIconButton,
  Breadcrumbs as MuiBreadcrumbs,
  ListItemText as MuiListItemText,
  TableContainer as MuiTableContainer,
  TablePagination as MuiTablePagination,
  CircularProgress as MuiCircularProgress,
} from "@mui/material";
import {
  Edit as MuiEditIcon,
  Info as MuiInfoIcon,
  ArrowBack as MuiArrowBackIcon,
  AddCircle as MuiAddCircleIcon,
  NavigateNext as MuiNavigateNextIcon,
  KeyboardArrowUp as MuiKeyboardArrowUpIcon,
  KeyboardArrowDown as MuiKeyboardArrowDownIcon,
} from "@mui/icons-material";
import { Theme, useAlerts, useUsers } from "common";
import {
  getFiscalYearFinancialHistory,
  getGLPeriodFinancialHistory,
} from "app/services/componentsService";
import { useCommitmentContext } from "app/services/commitmentContext";
import {
  ComponentTypes,
  MovedStatus,
  TransactionTypes,
} from "app/shared/constants";
import { CurrencyFormat } from "app/shared/ui/CurrencyFormat";
import { FinancialHistoryGLPeriodTableHead } from "./FinancialHistoryGLPeriodTableHead";
import { FinancialHistoryFiscalYearTableHead } from "./FinancialHistoryFiscalYearTableHead";

//Formatting of Project,task and string
function PTAFormat(project, task, award) {
  var pta = `${
    project ? project : "\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0"
  }-${task ? task : "\u00A0\u00A0\u00A0"}-${
    award ? award : "\u00A0\u00A0\u00A0\u00A0\u00A0"
  }`;
  return pta;
}

export const FinancialHistory = (props) => {
  const { t } = useTranslation();
  const { financialHistoryPagination, setFinancialHistoryPagination } =
    useCommitmentContext();

  const { commitmentId, componentId } = useParams();
  const [rowsPerPage, setRowsPerPage] = useState(
    (financialHistoryPagination.componentId &&
      financialHistoryPagination.componentId === componentId &&
      financialHistoryPagination.rowsPerPage) ||
      10
  );
  const [page, setPage] = useState(
    (financialHistoryPagination.componentId &&
      financialHistoryPagination.componentId === componentId &&
      financialHistoryPagination.page) ||
      0
  );
  const [
    fiscalYearFinancialHistoryDetails,
    setFiscalYearFinancialHistoryDetails,
  ] = useState();

  const [
    fiscalYearFinancialHistoryTotalCount,
    setFiscalYearFinancialHistoryTotalCount,
  ] = useState(0);

  const [loading, setLoading] = useState(false);

  const { clearAlert, setAlert } = useAlerts();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setFinancialHistoryPagination({
      ...financialHistoryPagination,
      page: newPage,
      componentId: componentId,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    var pageValue = page;
    const emptyRows = Math.min(
      parseInt(event.target.value, 10),
      fiscalYearFinancialHistoryTotalCount -
        page * parseInt(event.target.value, 10)
    );
    if (emptyRows < 0) {
      setPage(0);
      pageValue = 0;
    }
    setFinancialHistoryPagination({
      rowsPerPage: parseInt(event.target.value, 10),
      page: pageValue,
      componentId: componentId,
    });
  };

  const { currentUser } = useUsers();
  const { permissions } = currentUser;

  useEffect(() => {
    clearAlert();
    if (permissions.VIEW_COMPONENT_FINANCIAL_HISTORY) {
      getFiscalYearFinancialHistory(
        setFiscalYearFinancialHistoryDetails,
        setFiscalYearFinancialHistoryTotalCount,
        setLoading,
        commitmentId,
        componentId,
        page,
        rowsPerPage,
        clearAlert,
        setAlert
      );
    }
    // eslint-disable-next-line
  }, [commitmentId, componentId, page, rowsPerPage, permissions]);

  const commonTranslationStr = "Commitments.financialHistory.mainView";
  const commonTableColumnsStr = "Commitments.mainView.list.tableColumns";
  const commonComponentsTableColumnStr =
    "Commitments.commitmentComponents.mainView.list";

  const getComponentDetails = (value, status = "", key = "") => {
    const { movedFromComponent, movedToComponent } = value;

    if (status === MovedStatus.MOVED) {
      const { commitment, id: componentId, componentName } = movedFromComponent;
      const newValue = {
        commitmentId: commitment.id,
        componentId,
        componentName,
      };

      return newValue[key];
    }

    const { commitment, id: componentId, componentName } = movedToComponent;
    const newValue = {
      commitmentId: commitment.id,
      componentId,
      componentName,
    };

    return newValue[key];
  };

  return (
    permissions.VIEW_COMPONENT_FINANCIAL_HISTORY && (
      <MuiGrid container>
        {props.pageType !== "newTab" && (
          <MuiGrid container item xs={12}>
            <MuiGrid item container xs={6} justifyContent="flex-start">
              <MuiBreadcrumbs
                separator={<MuiNavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
              >
                <RouterLink
                  style={{ textDecoration: "none" }}
                  to={`/commitments`}
                >
                  <MuiTypography color="textSecondary" variant="body2">
                    {t("Commitments.mainView.title")}
                  </MuiTypography>
                </RouterLink>
                <MuiTypography color="textPrimary" variant="subtitle1">
                  {t(`${commonTranslationStr}.title`)}
                </MuiTypography>
              </MuiBreadcrumbs>
            </MuiGrid>
            <MuiGrid item container xs={6} justifyContent="flex-end">
              <RouterLink
                style={{ textDecoration: "none" }}
                to={`/commitments`}
              >
                <MuiButton startIcon={<MuiArrowBackIcon />}>
                  {t(`${commonTranslationStr}.goBackButtonLabel`)}
                </MuiButton>
              </RouterLink>
            </MuiGrid>
          </MuiGrid>
        )}
        <MuiGrid item xs={12}>
          <MuiTypography variant="h1">
            {t(`${commonTranslationStr}.title`)}
          </MuiTypography>
        </MuiGrid>
        <MuiGrid item xs={12}>
          <MuiPaper elevation={3}>
            <MuiBox display="flex" p={2}>
              {loading && (
                <MuiGrid container>
                  <MuiGrid item xs={12}>
                    <MuiBox display="flex" justifyContent="center">
                      <MuiCircularProgress />
                    </MuiBox>
                  </MuiGrid>
                </MuiGrid>
              )}

              {!loading && fiscalYearFinancialHistoryDetails && (
                <MuiGrid container>
                  <MuiGrid item xs={2} sx={{ flexBasis: "12.5%" }}>
                    <MuiTypography variant="body2">
                      {t(`${commonTableColumnsStr}.commitmentName`)}:
                    </MuiTypography>
                  </MuiGrid>

                  <MuiGrid item xs={10}>
                    <MuiTypography variant="subtitle1">
                      {fiscalYearFinancialHistoryDetails.commitment.name}
                    </MuiTypography>
                  </MuiGrid>

                  <MuiGrid item xs={2} sx={{ flexBasis: "12.5%" }}>
                    <MuiTypography variant="body2">
                      {t(
                        `${commonComponentsTableColumnStr}.tableColumns.componentName`
                      )}
                      :
                    </MuiTypography>
                  </MuiGrid>
                  <MuiGrid item xs={10}>
                    <MuiTypography variant="subtitle1">
                      {fiscalYearFinancialHistoryDetails.componentName}
                    </MuiTypography>
                  </MuiGrid>
                  <MuiGrid item xs={2} sx={{ flexBasis: "12.5%" }}>
                    <MuiTypography variant="body2">
                      {t(`${commonTableColumnsStr}.departmentName`)}:
                    </MuiTypography>
                  </MuiGrid>
                  <MuiGrid item xs={4}>
                    <MuiTypography variant="subtitle1">
                      {fiscalYearFinancialHistoryDetails.org.orgName} (
                      {fiscalYearFinancialHistoryDetails.org.orgCode})
                    </MuiTypography>
                  </MuiGrid>
                  <MuiGrid item xs={6} sx={{ flexBasis: "12.5%" }}>
                    <MuiTypography variant="subtitle1">
                      {fiscalYearFinancialHistoryDetails?.moveStatus && (
                        <Trans
                          i18nKey={
                            fiscalYearFinancialHistoryDetails.moveStatus ===
                            MovedStatus.MOVED
                              ? "Commitments.moveComponent.movedStatusComponent"
                              : "Commitments.moveComponent.originalStatusComponent"
                          }
                          values={{
                            componentName: getComponentDetails(
                              fiscalYearFinancialHistoryDetails,
                              fiscalYearFinancialHistoryDetails.moveStatus,
                              "componentName"
                            ),
                            moveDate: format(
                              new Date(
                                fiscalYearFinancialHistoryDetails.moveDate
                              ),
                              "MM/dd/yyyy"
                            ),
                            moveBy:
                              fiscalYearFinancialHistoryDetails?.moveBy ?? "",
                          }}
                          components={{
                            Link: (
                              <RouterLink
                                to={{
                                  pathname: `/commitments/${getComponentDetails(
                                    fiscalYearFinancialHistoryDetails,
                                    fiscalYearFinancialHistoryDetails.moveStatus,
                                    "commitmentId"
                                  )}/components/${getComponentDetails(
                                    fiscalYearFinancialHistoryDetails,
                                    fiscalYearFinancialHistoryDetails.moveStatus,
                                    "componentId"
                                  )}/financial-history`,
                                }}
                              />
                            ),
                          }}
                        />
                      )}
                    </MuiTypography>
                  </MuiGrid>

                  <MuiGrid item xs={6}>
                    <MuiGrid item container>
                      <MuiGrid item xs={3}>
                        <MuiTypography variant="body2">
                          {t(
                            "Commitments.commitmentComponents.create.form.fields.componentDate.label"
                          )}
                          :
                        </MuiTypography>
                      </MuiGrid>
                      <MuiGrid item>
                        <MuiTypography variant="subtitle1">
                          {fiscalYearFinancialHistoryDetails.componentDate}
                        </MuiTypography>
                      </MuiGrid>
                    </MuiGrid>
                    <MuiGrid item container>
                      <MuiGrid item xs={3}>
                        <MuiTypography variant="body2">
                          {t(
                            `${commonTranslationStr}.financialHistoryDetails.projectTaskAward`
                          )}
                          :
                        </MuiTypography>
                      </MuiGrid>
                      <MuiGrid item>
                        <MuiTypography variant="subtitle1">
                          {PTAFormat(
                            fiscalYearFinancialHistoryDetails.project,
                            fiscalYearFinancialHistoryDetails.task,
                            fiscalYearFinancialHistoryDetails.award
                          )}
                        </MuiTypography>
                      </MuiGrid>
                    </MuiGrid>
                  </MuiGrid>
                  <MuiGrid item xs={4}>
                    <MuiGrid item container>
                      <MuiGrid item xs={4}>
                        <MuiTypography variant="body2">
                          {t(
                            `${commonTranslationStr}.financialHistoryDetails.componentAmount`
                          )}
                          :
                        </MuiTypography>
                      </MuiGrid>
                      <MuiGrid item>
                        <MuiTypography variant="subtitle1">
                          {fiscalYearFinancialHistoryDetails.totalComponentAmount !==
                            undefined &&
                            CurrencyFormat(
                              fiscalYearFinancialHistoryDetails.totalComponentAmount
                            )}
                        </MuiTypography>
                      </MuiGrid>
                    </MuiGrid>
                    <MuiGrid item container>
                      <MuiGrid item xs={4}>
                        <MuiTypography variant="body2">
                          {t(
                            `${commonComponentsTableColumnStr}.tableColumns.unspentAmount`
                          )}
                          :
                        </MuiTypography>
                      </MuiGrid>
                      <MuiGrid item>
                        <MuiTypography variant="subtitle1">
                          {!fiscalYearFinancialHistoryDetails.isClosed &&
                            fiscalYearFinancialHistoryDetails.totalUnspentAmount !==
                              undefined &&
                            CurrencyFormat(
                              fiscalYearFinancialHistoryDetails.totalUnspentAmount
                            )}
                          {fiscalYearFinancialHistoryDetails.isClosed &&
                            fiscalYearFinancialHistoryDetails.totalUnspentAmount !==
                              undefined &&
                            CurrencyFormat(0)}
                        </MuiTypography>
                      </MuiGrid>
                    </MuiGrid>
                  </MuiGrid>
                  <MuiGrid item xs={2}>
                    <MuiGrid item container>
                      <MuiGrid item xs={4}>
                        <MuiTypography variant="body2">
                          {t(
                            "Commitments.commitmentDocuments.mainView.list.tableColumns.type"
                          )}
                          :
                        </MuiTypography>
                      </MuiGrid>
                      <MuiGrid item>
                        <MuiTypography variant="subtitle1">
                          {t(
                            ComponentTypes[
                              fiscalYearFinancialHistoryDetails.componentType
                            ]
                          )}
                        </MuiTypography>
                      </MuiGrid>
                    </MuiGrid>
                    <MuiGrid item container>
                      <MuiGrid item xs={4}>
                        <MuiTypography variant="body2">
                          {t(`${commonTableColumnsStr}.status`)}:
                        </MuiTypography>
                      </MuiGrid>
                      <MuiGrid item>
                        {fiscalYearFinancialHistoryDetails.isClosed && (
                          <MuiTypography variant="subtitle1">
                            {t(
                              `${commonComponentsTableColumnStr}.tableValues.statusClosedText`
                            )}{" "}
                            {fiscalYearFinancialHistoryDetails.closedDate}
                          </MuiTypography>
                        )}
                        {!fiscalYearFinancialHistoryDetails.isClosed && (
                          <MuiTypography variant="subtitle1">
                            {t(
                              `${commonComponentsTableColumnStr}.tableValues.statusOpenText`
                            )}
                          </MuiTypography>
                        )}
                      </MuiGrid>
                    </MuiGrid>
                  </MuiGrid>
                </MuiGrid>
              )}
            </MuiBox>
          </MuiPaper>
        </MuiGrid>
        <MuiGrid item xs={12}>
          <MuiBox display="flex" justifyContent="flex-end" mt={2} mb={2}>
            {permissions.CREATE_COMPONENT_FINANCIAL_PLAN && (
              <RouterLink
                style={{ textDecoration: "none" }}
                to={`/commitments/${commitmentId}/components/${componentId}/financial-history/new`}
              >
                <MuiButton
                  variant="contained"
                  color="primary"
                  startIcon={<MuiAddCircleIcon />}
                >
                  {t(`${commonTranslationStr}.addFiscalYearBtn`)}
                </MuiButton>
              </RouterLink>
            )}
          </MuiBox>
        </MuiGrid>
        <MuiGrid item xs={12}>
          <MuiTableContainer component={MuiPaper}>
            <MuiTable
              aria-label="customized table"
              stickyHeader
              sx={{ tableLayout: "fixed" }}
            >
              <FinancialHistoryFiscalYearTableHead />
              <MuiTableBody>
                {loading && (
                  <MuiTableRow>
                    <MuiTableCell colSpan="11">
                      <MuiBox display="flex" justifyContent="center">
                        <MuiCircularProgress />
                      </MuiBox>
                    </MuiTableCell>
                  </MuiTableRow>
                )}
                {!loading &&
                  fiscalYearFinancialHistoryTotalCount > 0 &&
                  fiscalYearFinancialHistoryDetails.componentFinancialHistory.values.map(
                    (row, index) => (
                      <CollapsibleTable
                        key={index}
                        fiscalYearRow={row}
                        commitmentId={
                          fiscalYearFinancialHistoryDetails.commitment.id
                        }
                        componentId={fiscalYearFinancialHistoryDetails.id}
                      />
                    )
                  )}
                {!loading && fiscalYearFinancialHistoryTotalCount === 0 && (
                  <MuiTableRow>
                    <MuiTableCell colSpan="11">
                      <MuiTypography
                        color="inherit"
                        align="center"
                        paragraph={false}
                      >
                        {t(
                          `${commonTranslationStr}.list.noFinancialHistoryMsg`
                        )}
                      </MuiTypography>
                    </MuiTableCell>
                  </MuiTableRow>
                )}
              </MuiTableBody>
            </MuiTable>

            <MuiTablePagination
              rowsPerPageOptions={[10, 25, 50, 100]}
              component="div"
              count={fiscalYearFinancialHistoryTotalCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </MuiTableContainer>
        </MuiGrid>
        {props.pageType !== "newTab" && (
          <MuiGrid item xs={12}>
            <MuiBox display="flex" justifyContent="flex-end" mt={2} mb={2}>
              <RouterLink
                style={{ textDecoration: "none" }}
                to={`/commitments`}
              >
                <MuiButton variant="outlined" startIcon={<MuiArrowBackIcon />}>
                  {t(`${commonTranslationStr}.goBackButtonLabel`)}
                </MuiButton>
              </RouterLink>
            </MuiBox>
          </MuiGrid>
        )}
      </MuiGrid>
    )
  );
};

function CollapsibleTable({ fiscalYearRow, commitmentId, componentId }) {
  const [open, setOpen] = useState(false);
  const [gLPeriodFinancialHistoryDetails, setGLPeriodFinancialHistoryDetails] =
    useState();
  const [gLPeriodFinancialHistoryCount, setGLPeriodFinancialHistoryCount] =
    useState(0);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const { setAlert } = useAlerts();

  const {
    financialHistorySelectedFiscalYear,
    setFinancialHistorySelectedFiscalYear,
  } = useCommitmentContext();

  const [anchorEl, setAnchorEl] = useState(null);
  const [
    manualFinancialTransactionDetails,
    setManualFinancialTransactionDetails,
  ] = useState();

  const getGLPeriodDetails = useCallback(
    (fiscalYear) => {
      getGLPeriodFinancialHistory(
        setGLPeriodFinancialHistoryDetails,
        setGLPeriodFinancialHistoryCount,
        setLoading,
        setAlert,
        { commitmentId, componentId, fiscalYear }
      );
    },
    // eslint-disable-next-line
    [commitmentId, componentId]
  );

  const getDetails = useCallback(() => {
    if (financialHistorySelectedFiscalYear === fiscalYearRow.fiscalYear) {
      getGLPeriodDetails(financialHistorySelectedFiscalYear);
      setOpen(true);
    }
  }, [
    financialHistorySelectedFiscalYear,
    getGLPeriodDetails,
    fiscalYearRow.fiscalYear,
  ]);

  useEffect(() => {
    getDetails();
  }, [getDetails]);

  const commonTranslationStr =
    "Commitments.financialHistory.mainView.list.fiscalYearTable";

  const manualTrasactionEntryCommonTranslationStr =
    "Commitments.financialHistory.mainView.list.glPeriodTable.manualTransactionEntryDetails";

  const handlePopoverOpen = (event, row) => {
    setAnchorEl(event.currentTarget);
    setManualFinancialTransactionDetails(row);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const openWarningPopover = Boolean(anchorEl);

  function ManualTransactionEntryDetails() {
    return (
      <MuiPopover
        id="mouse-over-popover"
        open={openWarningPopover}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <MuiList>
          <MuiListItem alignItems="center" dense>
            <MuiListItemText
              disableTypography
              primary={
                <MuiGrid container>
                  <MuiGrid item xs={12}>
                    <MuiTypography variant="subtitle1">
                      {manualFinancialTransactionDetails &&
                        manualFinancialTransactionDetails.glPeriod}
                    </MuiTypography>
                  </MuiGrid>
                </MuiGrid>
              }
            />
          </MuiListItem>
        </MuiList>
        {manualFinancialTransactionDetails &&
          manualFinancialTransactionDetails.manualFinancialTransactionDtos.map(
            (
              manualFinancialTransactionRow,
              manualFinancialTransactionIndex
            ) => (
              <Fragment key={manualFinancialTransactionIndex}>
                <MuiList>
                  <MuiListItem alignItems="flex-start" dense>
                    <MuiListItemText
                      disableTypography
                      secondary={
                        <MuiGrid container>
                          <MuiGrid item xs={2}>
                            <MuiTypography
                              variant="body2"
                              color="textSecondary"
                            >
                              {t(
                                `${manualTrasactionEntryCommonTranslationStr}.entryLabel`
                              )}{" "}
                              {manualFinancialTransactionRow.periodEntryNumber}:
                            </MuiTypography>
                          </MuiGrid>
                          <MuiGrid item xs={10}>
                            <MuiTypography
                              variant="body2"
                              color="textSecondary"
                            >
                              {t(
                                TransactionTypes[
                                  manualFinancialTransactionRow.transactionType
                                ]
                              )}{" "}
                              {manualFinancialTransactionRow.transactionAmount !==
                                undefined &&
                                CurrencyFormat(
                                  manualFinancialTransactionRow.transactionAmount
                                )}
                            </MuiTypography>
                          </MuiGrid>
                          <MuiGrid container>
                            <MuiGrid item xs={12}>
                              <MuiTypography
                                variant="body2"
                                color="textSecondary"
                              >
                                {manualFinancialTransactionRow.transactionNotes}
                              </MuiTypography>
                            </MuiGrid>
                          </MuiGrid>
                          <MuiGrid item xs={12}>
                            <MuiTypography
                              variant="body2"
                              color="textSecondary"
                            >
                              {t(
                                `${manualTrasactionEntryCommonTranslationStr}.enteredByLabel`
                              )}{" "}
                              {manualFinancialTransactionRow.enteredBy}{" "}
                              {t(
                                `${manualTrasactionEntryCommonTranslationStr}.onLabel`
                              )}{" "}
                              {format(
                                new Date(
                                  manualFinancialTransactionRow.enteredDate.toLocaleString()
                                ),
                                "MM/dd/yyyy"
                              )}
                            </MuiTypography>
                          </MuiGrid>
                        </MuiGrid>
                      }
                    />
                  </MuiListItem>
                </MuiList>
              </Fragment>
            )
          )}
      </MuiPopover>
    );
  }

  return (
    <>
      <MuiTableRow>
        <MuiTableCell>
          {fiscalYearRow.hasDetails && (
            <MuiIconButton
              aria-label="expand row"
              size="small"
              onClick={() => {
                setOpen(!open);
                setFinancialHistorySelectedFiscalYear(fiscalYearRow.fiscalYear);
              }}
            >
              {open ? <MuiKeyboardArrowUpIcon /> : <MuiKeyboardArrowDownIcon />}
            </MuiIconButton>
          )}
        </MuiTableCell>
        <MuiTableCell component="th" scope="row" align="left">
          {`${fiscalYearRow.fiscalYear}${
            fiscalYearRow.fiscalYear === 2010
              ? t("Commitments.financialHistory.mainView.andPriorSuffix")
              : ""
          }`}
        </MuiTableCell>
        <MuiTableCell align="right">
          {getAmount(fiscalYearRow.expenseAmount)}
        </MuiTableCell>
        <MuiTableCell align="right">
          {getAmount(fiscalYearRow.transferAmount)}
        </MuiTableCell>
        <MuiTableCell align="right">
          {getAmount(fiscalYearRow.actualAmount)}
        </MuiTableCell>
        <MuiTableCell align="right">
          {getAmount(fiscalYearRow.capAmount)}
        </MuiTableCell>
        <MuiTableCell align="right">
          {getAmount(fiscalYearRow.yepAmount)}
        </MuiTableCell>
        <MuiTableCell align="right">
          {getAmount(fiscalYearRow.budgetAmount)}
        </MuiTableCell>
        <MuiTableCell align="right">
          {getAmount(fiscalYearRow.planAmount)}
        </MuiTableCell>
        <MuiTableCell align="right">
          {getAmount(fiscalYearRow.lrffAmount)}
        </MuiTableCell>
        <MuiTableCell align="center">
          {fiscalYearRow._links.edit && (
            <RouterLink
              style={{ textDecoration: "none" }}
              to={`/commitments/${commitmentId}/components/${componentId}/financial-history/${fiscalYearRow.fiscalYear}/${fiscalYearRow.id}/edit`}
            >
              <MuiTooltip
                title={t(`${commonTranslationStr}.editFiscalYearTooltip`)}
              >
                <MuiIconButton size="small">
                  <MuiEditIcon />
                </MuiIconButton>
              </MuiTooltip>
            </RouterLink>
          )}
          {fiscalYearRow._links.create && (
            <MuiTooltip
              title={t(`${commonTranslationStr}.addTransactionTooltip`)}
            >
              <RouterLink
                style={{ textDecoration: "none" }}
                to={`/commitments/${commitmentId}/components/${componentId}/financial-history/${fiscalYearRow.fiscalYear}/financial-transaction/new`}
              >
                <MuiIconButton size="small">
                  <MuiAddCircleIcon />
                </MuiIconButton>
              </RouterLink>
            </MuiTooltip>
          )}
        </MuiTableCell>
      </MuiTableRow>
      {!loading && gLPeriodFinancialHistoryCount > 0 && (
        <MuiTableRow
          sx={{
            backgroundColor: Theme.palette.primary.light,
            "& .MuiTableCell-head": {
              color: Theme.palette.grey[700],
              fontSize: 13,
              fontWeight: 600,
            },
            "& .MuiTableCell-body": {
              borderBottom: "unset",
              fontSize: 13,
              fontWeight: 500,
            },
          }}
        >
          <MuiTableCell
            style={{
              paddingBottom: 0,
              paddingTop: 0,
              paddingLeft: 20,
              paddingRight: 20,
            }}
            colSpan={11}
          >
            <MuiCollapse
              in={open}
              timeout="auto"
              unmountOnExit
              sx={{ width: "48%" }}
            >
              <MuiBox>
                <MuiTable
                  size="small"
                  aria-label="purchases"
                  sx={{ tableLayout: "fixed" }}
                >
                  <FinancialHistoryGLPeriodTableHead />
                  <MuiTableBody>
                    {gLPeriodFinancialHistoryDetails.map((row, index) => (
                      <MuiTableRow key={index}>
                        <MuiTableCell align="left">{`${row.glPeriod}${
                          row.glPeriod === "AUG-2010"
                            ? t(
                                "Commitments.financialHistory.mainView.andPriorSuffix"
                              )
                            : ""
                        }`}</MuiTableCell>
                        <MuiTableCell align="right">
                          {getAmount(row.mtdExpense)}
                        </MuiTableCell>
                        <MuiTableCell align="right">
                          {getAmount(row.mtdTransfer)}
                        </MuiTableCell>
                        <MuiTableCell align="right">
                          {getAmount(row.mtdActual)}
                        </MuiTableCell>
                        <MuiTableCell>
                          {row.manualEntryInfoIcon && (
                            <>
                              <MuiIconButton
                                size="small"
                                onClick={(e) => {
                                  handlePopoverOpen(e, row);
                                }}
                              >
                                <MuiInfoIcon />
                              </MuiIconButton>
                              {row.manualFinancialTransactionDtos.length >
                                0 && <ManualTransactionEntryDetails />}
                            </>
                          )}
                        </MuiTableCell>
                      </MuiTableRow>
                    ))}
                  </MuiTableBody>
                </MuiTable>
              </MuiBox>
            </MuiCollapse>
          </MuiTableCell>
        </MuiTableRow>
      )}
    </>
  );
}

// getAmount - to format amount
export const getAmount = (amount) => {
  if (amount) {
    return CurrencyFormat(amount);
  } else {
    return "";
  }
};
