import axios from "axios";
import { translateComponentFormData } from "app/shared/utils";

// GET :- Award details
export const getAwardDetails = async (
  awardNumber,
  setAwardDetails,
  setValidateAward
) => {
  try {
    const response = await axios(`ptas/awards/${awardNumber}`);
    setAwardDetails(response.data);
    setValidateAward("EXIST");
  } catch (error) {
    if (error.status === 404 && error.code === "RESOURCE_NOT_FOUND") {
      awardNumber && setValidateAward("NOTEXIST");
    } else {
      setValidateAward();
    }
  }
};

// GET :- Project details
export const getProjectDetails = async (
  awardNumber,
  projectNumber,
  setProjectDetails,
  setValidateProject
) => {
  try {
    const response = await axios(
      `ptas/awards/${awardNumber}/projects/${projectNumber}`
    );
    setProjectDetails(response.data);
    setValidateProject("EXIST");
  } catch (error) {
    if (error.status === 404 && error.code === "RESOURCE_NOT_FOUND") {
      projectNumber && setValidateProject("NOTEXIST");
    } else {
      setValidateProject();
    }
  }
};

// GET :- Task details
export const getTaskDetails = async (
  awardNumber,
  projectNumber,
  taskNumber,
  setTaskDetails,
  setValidateTask,
  setPtaTaskName,
  piOrComponentOwnerValue,
  setPiOrComponentOwnerValue,
  setShowChangeOwnerDialog,
  taskValueChange
) => {
  try {
    const response = await axios(
      `ptas/awards/${awardNumber}/projects/${projectNumber}/tasks/${taskNumber}`
    );
    const responseData = response.data;
    setTaskDetails(responseData);
    setValidateTask("EXIST");
    setPtaTaskName(responseData.taskName);
    if (taskValueChange) {
      if (!piOrComponentOwnerValue || !piOrComponentOwnerValue.displayText) {
        setPiOrComponentOwnerValue({
          displayText: responseData.taskOwnerFullName,
        });
      }

      if (
        piOrComponentOwnerValue &&
        responseData.taskOwnerFullName &&
        piOrComponentOwnerValue.displayText &&
        piOrComponentOwnerValue.displayText
          .trim()
          .toLowerCase()
          .localeCompare(
            responseData.taskOwnerFullName.trim().toLowerCase()
          ) !== 0
      )
        setShowChangeOwnerDialog(true);
    }
  } catch (error) {
    if (error.status === 404 && error.code === "RESOURCE_NOT_FOUND") {
      taskNumber && setValidateTask("NOTEXIST");
    } else {
      setValidateTask();
    }
  }
};

export const getAwardAutocomplete = async (
  value,
  setAwardList,
  setLoading,
  setFieldError
) => {
  const size = 10;
  try {
    setFieldError("award", "");
    setLoading(true);
    const response = await axios(
      `ptas/awards/autocomplete?size=${size}&value=${value}`
    );
    setAwardList(
      response.data.values && response.data.values.map((i) => i.displayText)
    );
  } catch (error) {
    setFieldError("award", error.message);
  } finally {
    setLoading(false);
  }
};

export const getProjectAutocomplete = async (
  value,
  award,
  setProjectList,
  setLoading,
  setFieldError
) => {
  const size = 10;
  try {
    setFieldError("project", "");
    setLoading(true);
    const response = await axios(
      `ptas/awards/${award}/projects/autocomplete?size=${size}&value=${value}`
    );
    setProjectList(
      response.data.values && response.data.values.map((i) => i.displayText)
    );
  } catch (error) {
    setFieldError("project", error.message);
  } finally {
    setLoading(false);
  }
};

export const getTaskAutocomplete = async (
  value,
  award,
  project,
  setTaskList,
  setLoading,
  setFieldError
) => {
  const size = 10;
  try {
    setFieldError("task", "");
    setLoading(true);
    const response = await axios(
      `ptas/awards/${award}/projects/${project}/tasks/autocomplete?size=${size}&value=${value}`
    );
    setTaskList(
      response.data.values && response.data.values.map((i) => i.displayText)
    );
  } catch (error) {
    setFieldError("task", error.message);
  } finally {
    setLoading(false);
  }
};

export const getExpenditureRestrictionsByMatch = async (
  setLoading,
  setExpenditureRestrictions,
  value,
  setFieldError
) => {
  const size = 25;
  try {
    setFieldError("expenditures", "");
    setLoading(true);
    const response = await axios(
      `/expenditures/autocomplete?size=${size}&value=${value}`
    );
    setExpenditureRestrictions(response.data.values || []);
  } catch (error) {
    setFieldError("expenditures", error.message);
  } finally {
    setLoading(false);
  }
};

export const getPIOrComponentOwnerByMatch = async (
  value,
  onBeforeSend,
  onError,
  onSuccess,
  cancelSource
) => {
  const size = 25;
  try {
    onBeforeSend();

    const response = await axios(
      `/components/component-owners/autocomplete?size=${size}&value=${value}`,
      { cancelToken: cancelSource?.token }
    );

    onSuccess(response.data);
  } catch (error) {
    onError(error);
  }
};

export const getComponentById = async (
  setFormDataResponse,
  setClosed,
  setPiOrComponentOwnerValue,
  setValidateAward,
  setValidateProject,
  setValidateTask,
  setPtaTaskName,
  commitmentId,
  componentId,
  setAlert,
  clearAlert,
  cancelSource
) => {
  try {
    clearAlert();
    // translates the API response to the UI form format and sets the state
    const response = await axios(
      `/commitments/${commitmentId}/components/${componentId}`,
      { cancelToken: cancelSource?.token }
    );
    const data = response.data;
    setFormDataResponse({
      componentName: data.componentName,
      componentDescription: data.componentDescription,
      componentIncrementals: data.componentIncrementals?.map((component) => ({
        ...component,
        componentDate: component.componentDate
          ? new Date(component.componentDate)
          : null,
      })),
      totalComponentAmountRequested: data.totalComponentAmountRequested,
      totalComponentAmountApproved: data.totalComponentAmountApproved,
      project: data.project,
      task: data.task,
      award: data.award,
      piOrComponentOwner: data.piOrComponentOwner
        ? { displayText: data.piOrComponentOwner }
        : "",
      componentType: data.componentType,
      purpose1: data.purpose1,
      purpose2: data.purpose2,
      expenditures:
        Array.isArray(data.expenditures) && data.expenditures.length > 0
          ? data.expenditures.map((i) => ({
              displayText: `${i.name} (${i.code})`,
              value: { code: i.code, id: i.id, name: i.name },
            }))
          : [],
      startDate: data.startDate ? new Date(data.startDate) : null,
      expirationDate: data.expirationDate
        ? new Date(data.expirationDate)
        : null,
      durationNotes: data.durationNotes,
      componentNotes: data.componentNotes,
      closed: data.closed,
      closedDate: data.closedDate ? new Date(data.closedDate) : null,
      closedDetails: data.closedDetails,
      unspentComponent: data.totalUnspentAmount
        ? parseFloat(data.totalUnspentAmount)
        : 0,
      totalComponentAmount: data.totalComponentAmount
        ? parseFloat(data.totalComponentAmount)
        : 0,
      recordNumber: data.recordNumber,
      templateSourceType: data.templateSourceType,
      moveDate: data.moveDate,
      moveStatus: data.moveStatus,
      movedFromComponent: data.movedFromComponent,
      movedToComponent: data.movedToComponent,
      moveBy: data.moveBy,
    });
    setClosed(data.closed);
    setPiOrComponentOwnerValue(
      data.piOrComponentOwner ? { displayText: data.piOrComponentOwner } : ""
    );
    setValidateAward(
      (data.validAward && data.award && "EXIST") ||
        (!data.validAward && data.award && "NOTEXIST")
    );
    setValidateProject(
      (data.validProject && data.project && "EXIST") ||
        (!data.validProject && data.project && "NOTEXIST")
    );
    setValidateTask(
      (data.validTask && data.task && "EXIST") ||
        (!data.validTask && data.task && "NOTEXIST")
    );
    setPtaTaskName(data.taskName ? data.taskName : "");
  } catch (error) {
    if (!axios.isCancel(error)) {
      setAlert("error", error.message);
    }
  }
};

export const getComponentByIdBrief = async (
  commitmentId,
  componentId,
  cancelSource,
  beforeSend,
  onSuccess,
  onError
) => {
  try {
    beforeSend();

    const response = await axios(
      `/commitments/${commitmentId}/components/${componentId}?vw=BRIEF`,
      { cancelToken: cancelSource?.token }
    );

    onSuccess(response.data);
  } catch (error) {
    onError(error);
  }
};

export const getComponentNamesLowercase = async (
  commitmentId,
  setComponentNameList,
  setLoading,
  setAlert,
  cancelSource
) => {
  try {
    setLoading(true);
    const response = await axios({
      url: `commitments/${commitmentId}/components`,
      cancelToken: cancelSource?.token,
      params: {
        vw: "BRIEF",
      },
    });
    setComponentNameList(
      response.data.values &&
        response.data.values.map((i) => i.componentName.toLowerCase())
    );
  } catch (error) {
    if (axios.isCancel(error)) {
      //console.log('successfully aborted');
    } else {
      setAlert("error", error.message);
    }
  } finally {
    setLoading(false);
  }
};

export const postComponent = async (
  values,
  piOrComponentOwnerValue,
  setLoading,
  commitmentId,
  componentId,
  commitmentName,
  setFieldError,
  setSubmitting,
  clearAlert,
  setAlert,
  navigate,
  t,
  returnRoute
) => {
  try {
    clearAlert();
    setLoading(true);

    // getting translated component form data that API expects
    const translatedData = translateComponentFormData(
      values,
      piOrComponentOwnerValue
    );

    await axios({
      method: "post",
      url: `/commitments/${commitmentId}/components`,
      data: translatedData,
    });

    if (values.componentType === "REQUEST") {
      navigate("/budgeting");
    } else {
      navigate(`/${returnRoute}`);
    }

    setAlert(
      "success",
      t("Commitments.commitmentComponents.create.notification.success", {
        componentName: `"${values.componentName.trim()}"`,
        commitmentName: `"${commitmentName.trim()}"`,
      }),
      true
    );
  } catch (error) {
    if (error.status === 500) handleServerError(setAlert, t, error);
    else {
      const [{ name, message }] =
        error.response.data.detail && error.response.data.detail.properties;
      if (error.status === 409 && error.code === "RESOURCE_EXISTS") {
        window.scrollTo(0, 0);
        setFieldError(
          "componentName",
          t(
            "Commitments.commitmentComponents.create.form.fields.componentName.validation.isDuplicate"
          )
        );
      } else if (
        error.status === 400 &&
        error.code === "INVALID_VALUE" &&
        name === "expenditures"
      ) {
        setFieldError("expenditures", message);
      } else {
        setAlert("error", error.message);
      }
    }
    setSubmitting(false);
  }
};

export const deleteComponent = async (
  commitmentId,
  componentId,
  setLoading,
  clearAlert,
  setAlert,
  navigate,
  t
) => {
  try {
    clearAlert();
    setLoading(true);
    await axios({
      method: "delete",
      url: `/commitments/${commitmentId}/components/${componentId}`,
    });

    navigate("/budgeting");

    setAlert(
      "success",
      t("Commitments.commitmentComponents.delete.notification.success"),
      true
    );
  } catch (error) {
    if (error.status === 500) handleServerError(setAlert, t, error);
    else {
      setAlert("error", error.message);
    }
  }
};

export const putComponent = async (
  values,
  piOrComponentOwnerValue,
  setLoading,
  commitmentId,
  componentId,
  commitmentName,
  setFieldError,
  setSubmitting,
  clearAlert,
  setAlert,
  navigate,
  t,
  returnRoute
) => {
  try {
    clearAlert();
    setLoading(true);

    // getting translated component form data that API expects
    const translatedData = translateComponentFormData(
      values,
      piOrComponentOwnerValue
    );

    await axios({
      method: "put",
      url: `/commitments/${commitmentId}/components/${componentId}`,
      data: translatedData,
    });

    if (values.componentType === "REQUEST") {
      navigate("/budgeting");
    } else {
      navigate(`/${returnRoute}`);
    }

    setAlert(
      "success",
      t("Commitments.commitmentComponents.edit.notification.success", {
        componentName: `"${values.componentName.trim()}"`,
        commitmentName: `"${commitmentName.trim()}"`,
      }),
      true
    );
  } catch (error) {
    if (error.status === 500) handleServerError(setAlert, t, error);
    else {
      const [{ name, message }] = error.response.data.detail?.properties;
      if (error.status === 409 && error.code === "RESOURCE_EXISTS") {
        window.scrollTo(0, 0);
        setFieldError(
          "componentName",
          t(
            "Commitments.commitmentComponents.create.form.fields.componentName.validation.isDuplicate"
          )
        );
      } else if (
        error.status === 400 &&
        error.code === "INVALID_VALUE" &&
        name === "expenditures"
      ) {
        setFieldError("expenditures", message);
      } else {
        setAlert("error", error.message);
      }
    }
    setSubmitting(false);
  }
};

// post financial history for fiscal year
export const postFinancialHistory = async (
  commitmentId,
  componentId,
  values,
  fiscalYear,
  setLoading,
  clearAlert,
  setAlert,
  navigate,
  t
) => {
  try {
    clearAlert();
    const data = {
      budgetAmount: values.budgetAmount || 0,
      fiscalYear: fiscalYear,
      lrffAmount: values.longRangeFinancialForecast || 0,
      planAmount: values.planAmount || 0,
      yepAmount: values.yepAmount || 0,
      capAmount: values.fySpendCap || 0,
    };
    setLoading(true);
    await axios({
      method: "post",
      url: `/commitments/${commitmentId}/components/${componentId}/component-financial-plans`,
      data,
    });

    navigate(
      `/commitments/${commitmentId}/components/${componentId}/financial-history`
    );
    setAlert(
      "success",
      t("Commitments.financialHistory.create.notification.success", {
        fiscalYear,
      }),
      true
    );
  } catch (error) {
    setAlert("error", error.message);
  }
};

export const getFiscalYearFinancialHistory = async (
  setFiscalYearFinancialHistoryDetails,
  setFiscalYearFinancialHistoryTotalCount,
  setLoading,
  commitmentId,
  componentId,
  page,
  rowsPerPage,
  clearAlert,
  setAlert
) => {
  try {
    clearAlert();
    const request = {
      url: `commitments/${commitmentId}/components/${componentId}/financial-histories`,
      params: {
        p: page + 1,
        ps: rowsPerPage,
      },
    };

    setLoading(true);
    const response = await axios(request);

    setFiscalYearFinancialHistoryDetails(response.data);
    setFiscalYearFinancialHistoryTotalCount(
      response.data.componentFinancialHistory.totalCount
    );
  } catch (error) {
    setAlert("error", error.message);
  } finally {
    setLoading(false);
  }
};

// put financial history for fiscal year
export const putFinancialHistory = async (
  commitmentId,
  componentId,
  componentPlanId,
  values,
  fiscalYear,
  setLoading,
  clearAlert,
  setAlert,
  navigate,
  t
) => {
  try {
    clearAlert();
    const data = {
      budgetAmount: values.budgetAmount || 0,
      fiscalYear: fiscalYear,
      lrffAmount: values.longRangeFinancialForecast || 0,
      planAmount: values.planAmount || 0,
      yepAmount: values.yepAmount || 0,
      capAmount: values.fySpendCap || 0,
    };
    setLoading(true);
    await axios({
      method: "put",
      url: `/commitments/${commitmentId}/components/${componentId}/component-financial-plans/${componentPlanId}`,
      data: data,
    });

    navigate(
      `/commitments/${commitmentId}/components/${componentId}/financial-history`
    );
    setAlert(
      "success",
      t("Commitments.financialHistory.edit.notification.success", {
        fiscalYear,
      }),
      true
    );
  } catch (error) {
    setAlert("error", error.message);
  }
};

export const getGLPeriodFinancialHistory = async (
  setGLPeriodFinancialHistoryDetails,
  setGLPeriodFinancialHistoryCount,
  setLoading,
  setAlert,
  { commitmentId, componentId, fiscalYear }
) => {
  try {
    const request = {
      url: `commitments/${commitmentId}/components/${componentId}/financial-histories/fiscal-year/${fiscalYear}/details`,
    };

    setLoading(true);
    const response = await axios(request);

    setGLPeriodFinancialHistoryDetails(response.data.values);
    setGLPeriodFinancialHistoryCount(response.data.count);
  } catch (error) {
    setAlert("error", error.message);
  } finally {
    setLoading(false);
  }
};

export const getFiscalYear = async (
  setFiscalYear,
  componentId,
  setAlert,
  clearAlert
) => {
  try {
    clearAlert();
    const response = await axios(
      `components/${componentId}/component-financial-planning-fiscal-year-info`
    );
    setFiscalYear(response.data.fiscalYear || "");
  } catch (error) {
    setAlert("error", error.message);
  }
};

export const getCommitmentNameById = async (
  setCommitmentName,
  commitmentId,
  setAlert,
  clearAlert,
  cancelSource
) => {
  try {
    clearAlert();
    const response = await axios(`commitments/${commitmentId}?vw=brief`, {
      cancelToken: cancelSource?.token,
    });
    setCommitmentName(response.data.name || "");
  } catch (error) {
    if (axios.isCancel(error)) {
      //console.log('successfully aborted');
    } else {
      setAlert("error", error.message);
    }
  }
};

export const getFinancialHistoryById = async (
  id,
  commitmentId,
  componentId,
  setFormDataResponse,
  clearAlert,
  setAlert
) => {
  try {
    clearAlert();
    const response = await axios(
      `/commitments/${commitmentId}/components/${componentId}/component-financial-plans/${id}`
    );
    setFormDataResponse({
      budgetAmount: response.data.budgetAmount,
      planAmount: response.data.planAmount,
      longRangeFinancialForecast: response.data.lrffAmount,
      yepAmount: response.data.yepAmount,
      fySpendCap: response.data.capAmount,
    });
  } catch (error) {
    setAlert("error", error.message);
  }
};

export const getGLPeriodsByFiscalYear = async (
  fiscalYear,
  setGLPeriodOptions,
  clearAlert,
  setAlert
) => {
  try {
    clearAlert();
    const response = await axios(
      `/components/fiscal-year/${fiscalYear}/gl-periods`
    );
    setGLPeriodOptions(response.data.glPeriods);
  } catch (error) {
    setAlert("error", error.message);
  }
};

// post financial transaction for fiscal year
export const postFinancialTransaction = async (
  commitmentId,
  componentId,
  fiscalYear,
  values,
  clearAlert,
  setAlert,
  t,
  navigate
) => {
  try {
    clearAlert();
    const data = {
      amount: values.amount,
      glPeriod: values.glPeriod.glPeriod,
      note: values.note,
      transactionType: values.type,
    };
    const response = await axios({
      method: "post",
      url: `/commitments/${commitmentId}/components/${componentId}/financial-histories/fiscal-year/${fiscalYear}`,
      data,
    });
    navigate({
      pathname: `/commitments/${commitmentId}/components/${componentId}/financial-history`,
    });
    setAlert(
      "success",
      t(
        "Commitments.financialHistory.financialTransaction.create.notification.success",
        {
          periodEntryNumber: `${values.glPeriod.glPeriod}/${response.data.id}`,
        }
      ),
      true
    );
  } catch (error) {
    setAlert("error", error.message);
  }
};

export const getComponentRequestTemplatesByType = async (
  commitmentRequestType,
  setComponentRequestTemplates,
  setLoading,
  clearAlert,
  setAlert,
  cancelSource
) => {
  commitmentRequestType = commitmentRequestType || "NON_RECRUITMENT";
  try {
    clearAlert();
    setLoading(true);
    const response = await axios(
      `component-templates?commitmentRequestType=${commitmentRequestType}`,
      { cancelToken: cancelSource?.token }
    );
    setComponentRequestTemplates(response.data.values);
  } catch (error) {
    setAlert("error", error.message);
  } finally {
    setLoading(false);
  }
};

const handleServerError = (setAlert, t, error) => {
  setAlert("error", t("common:apiErrorDefaultMessages.REQUEST_ERROR"));
  if (error?.response?.data?.message) {
    console.error(error.response.data.message);
  }
};

export const postMoveComponent = async (
  targetCommitmentId,
  sourceComponentId,
  values,
  clearAlert,
  setAlert,
  t,
  navigate,
  closeDialog,
  setLoading
) => {
  try {
    clearAlert();
    setLoading(true);
    const response = await axios({
      method: "post",
      url: `/components/${sourceComponentId}/move-link`,
      data: values,
    });

    const { id: targetComponentId } = response.data;
    const historyState = {
      successMessage: t("Commitments.moveComponent.successMessage"),
    };

    closeDialog();
    navigate(
      `/commitments/${targetCommitmentId}/components/${targetComponentId}/edit`,
      {
        state: historyState,
      }
    );
  } catch (error) {
    const { data } = error.response;
    if (data.code === "RESOURCE_EXISTS") {
      setAlert({
        type: "error",
        message: t("Commitments.moveComponent.duplicateComponentErrorMessage"),
      });
    } else {
      setAlert({
        type: "error",
        message: error.message,
      });
    }
  } finally {
    setLoading(false);
  }
};
